import 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import AppMain from '../../layout/AppMain';

const Main = ({
  colorScheme,
  enableFixedHeader,
  enableFixedSidebar,
  enableFixedFooter,
  enableClosedSidebar,
  enableMobileMenu,
  enablePageTabsAlt,
}) => {
  const { width, ref } = useResizeDetector();

  return (
    <div
      ref={ref}
      className={cx(
        'app-container app-theme-' + colorScheme,
        { 'fixed-header': enableFixedHeader },
        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
        { 'fixed-footer': enableFixedFooter },
        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
        { 'closed-sidebar-mobile': width < 1250 },
        { 'sidebar-mobile-open': enableMobileMenu },
        { 'body-tabs-shadow-btn': enablePageTabsAlt },
      )}
    >
      <AppMain />
    </div>
  );
};

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
