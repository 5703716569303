import React, { Suspense, lazy, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loader from 'react-loaders';
import PrivateRoute from '../../routes/PrivateRoute';
import HomePage from '../../pages/Home';

const AdminPages = lazy(() => import('../../pages/Admin'));
const Dashboards = lazy(() => import('../../pages/Dashboards'));
const DeletedAccounts = lazy(() => import('../../pages/DeletedAccounts'));
const LoginPages = lazy(() => import('../../pages/Login'));
const PostPages = lazy(() => import('../../pages/Posts'));
const ProductCategoryPages = lazy(() =>
  import('../../pages/ProductCategories'),
);
const ProductPages = lazy(() => import('../../pages/Products'));
const PropertyPages = lazy(() => import('../../pages/Properties'));
const PropertyRegistrationRequestPages = lazy(() =>
  import('../../pages/PropertyRegistrationRequests'),
);
const PropertyUnitPages = lazy(() => import('../../pages/PropertyUnits'));
const MessagesPages = lazy(() => import('../../pages/Messages'));
const RoomPages = lazy(() => import('../../pages/Rooms'));
const SignUpRequestPages = lazy(() => import('../../pages/SignUpRequests'));
const UserPages = lazy(() => import('../../pages/Users'));
const PropertyPartnerPages = lazy(() => import('../../pages/PropertyPartners'));
const ZonePages = lazy(() => import('../../pages/Zones'));

export default function AppMain(props) {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="pacman" />
              </div>
              <h6 className="mt-5"> Loading admin components... </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/admin" component={AdminPages} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="pacman" />
              </div>
              <h6 className="mt-5"> Loading IM components... </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/messages" component={MessagesPages} />
        <PrivateRoute path="/rooms" component={RoomPages} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5"> Loading properties components... </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/properties" component={PropertyPages} />
        <PrivateRoute
          path="/property-partners"
          component={PropertyPartnerPages}
        />
        <PrivateRoute
          path="/property-requests"
          component={PropertyRegistrationRequestPages}
        />
        <PrivateRoute path="/property-units" component={PropertyUnitPages} />
        <PrivateRoute path="/zones" component={ZonePages} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5"> Loading market components... </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/products" component={ProductPages} />
        <PrivateRoute
          path="/product-categories"
          component={ProductCategoryPages}
        />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5"> Loading newsfeed components... </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/posts" component={PostPages} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5"> Loading residents components... </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/deleted-accounts" component={DeletedAccounts} />
        <PrivateRoute path="/users" component={UserPages} />
        <PrivateRoute path="/signup-requests" component={SignUpRequestPages} />
      </Suspense>

      {/* Login */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
              <h6 className="mt-3"> Please wait... </h6>
            </div>
          </div>
        }
      >
        <Route path="/login" component={LoginPages} />
      </Suspense>

      {/* Dashboards */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-beat" />
              </div>
              <h6 className="mt-3">Loading dashboards components...</h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/dashboards" component={Dashboards} />
      </Suspense>

      <PrivateRoute exact={true} path="/" component={HomePage} />

      <ToastContainer />
    </Fragment>
  );
}
