import client from '@@api/client';

export const fetchMessages = ({
  page,
  limit,
  kinds,
  propertyIds,
  zoneIds,
  filteredRoomIds,
  attachmentOnly,
}: {
  page: number;
  limit: number;
  kinds: string[];
  propertyIds?: string[];
  zoneIds?: string[];
  filteredRoomIds?: string[];
  attachmentOnly?: boolean;
}) =>
  client.get('/im/messages', {
    params: {
      page,
      limit,
      kinds,
      propertyIds,
      zoneIds,
      filteredRoomIds,
      attachmentOnly,
    },
  });

  export const downloadMessages = ({
    propertyIds,
  }: {
    propertyIds?: string[];
  }) =>
    client.get('/im/messages/csv', {
      params: {
        page: 1,
        limit: 10000,
        propertyIds,
      },
    });

export const fetchRooms = ({
  page,
  limit,
  statuses,
  propertyIds,
}: {
  page: number;
  limit: number;
  statuses: string[];
  propertyIds: string[];
}) =>
  client.get('/im/rooms/list', {
    params: {
      page,
      limit,
      statuses,
      propertyIds,
    },
  });

export const fetchRoom = (id: string) =>
  client.get(`/im/rooms/${id}`);

export const patchRoom = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) =>
  client.patch(`/im/rooms/${id}`, payload);

export const searchRooms = (query: string) =>
  client.get('/im/rooms/search', {
    params: {
      query
    },
  });

export const createNewRoom = (data: {
  title: string;
  description: string;
  propertyId: string;
  isPublic: boolean;
  isReadOnly: boolean;
  roomType: string;
  scopes: {
    buildingId?: string;
    floors?: string[];
  }[];
  createdByUserId: string;
  invitedUserIds: string[];
}) => client.post('/im/rooms', data);

export const fetchRoomMembers = ({
  id,
  page,
  limit,
  selectedStatuses,
  selectedBuildingIds,
}: {
  id: string;
  page: number;
  limit: number;
  selectedStatuses: string[];
  selectedBuildingIds: string[];
}) => client.get(`/im/rooms/${id}/members`, {
  params: {
    page,
    limit,
    statuses: selectedStatuses,
    buildingIds: selectedBuildingIds,
  },
});

export const grantAdminRights = ({
  roomId,
  roomMemberId,
}: {
  roomId: string;
  roomMemberId: string;
}) =>
  client.post(`/im/rooms/${roomId}/members/${roomMemberId}/admin`);

export const removeAdminRights = ({
  roomId,
  roomMemberId,
}: {
  roomId: string;
  roomMemberId: string;
}) =>
  client.delete(`/im/rooms/${roomId}/members/${roomMemberId}/admin`);

export const deleteRoomMember = ({
  roomId,
  roomMemberId,
}: {
  roomId: string;
  roomMemberId: string;
}) =>
  client.delete(`/im/rooms/${roomId}/members/${roomMemberId}`);

export const checkMainRooms = (propertyId: string) =>
  client.post(`/im/properties/${propertyId}/check-main-rooms`);
