export type sortElem = {
  id: string;
  desc: boolean;
};

export const handleSortQuery = (sortQuery?: sortElem[]) =>
  sortQuery
    ?.map((query: sortElem) => `${query.id},${query.desc ? 'desc' : 'asc'}`)
    .join(',');

export const convertReactTableSortForApi = (sorted?: sortElem[]) => {
  if (!sorted) {
    return '';
  }

  return sorted.map(
    (sort: sortElem) => `&sort=${sort.id},${sort.desc ? 'desc' : 'asc'}`
  )
  .join('');
};
