import client from '@@api/client';
import { handleSortQuery, sortElem } from '@@utils/PaginationHelper';

export const fetchProducts = ({
  page,
  limit,
  statuses,
  userStatuses,
  productTypes,
  productCategories,
  selectedZones,
  sort,
}: {
  page: number;
  limit: number;
  statuses?: string[];
  userStatuses?: string[];
  productTypes?: string[];
  productCategories?: { id: string }[];
  selectedZones?: { id: string }[];
  sort?: sortElem[];
}) =>
  client.get('/market/admin/products', {
    params: {
      page,
      limit,
      statuses: statuses?.join(','),
      userStatuses: userStatuses?.join(','),
      productTypes: productTypes?.join(','),
      categoryIds: productCategories?.map((cat: { id: string }) => cat.id).join(','),
      zoneIds: selectedZones?.map((zone: { id: string }) => zone.id).join(','),
      sort: handleSortQuery(sort),
    },
  });

export const searchProducts = (query: string) =>
  client.get('/market/products/search', {
    params: {
      query,
    },
  });

export const fetchProduct = (id: string) =>
  client.get(`/market/products/${id}`);

export const patchProduct = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) =>
  client.patch(`/market/products/${id}`, payload);

export const fetchProductCategories = () =>
  client.get('/market/admin/categories');

export const fetchProductCategoriesByType = (productType: string) =>
  client.get(`/market/products/${productType.toLowerCase()}/categories`);

export const patchProductCategory = ({
  id,
  name,
  seoTitle,
}: {
  id: string;
  name: string;
  seoTitle: string;
}) =>
  client.patch(`/market/admin/categories/${id}`, {
    name,
    seoTitle,
  });

export const patchProductCategoriesOrder = ({
  ids
}: {
  ids: string[];
}) => client.patch('/market/admin/categories/order', {
  ids
});
