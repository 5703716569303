import client from '@@api/client';

export const fetchZones = () =>
  client.get('/properties/seo/zones', {
    params: {
      all: true,
    },
  });

export const fetchZone = (id: string) => client.get(`/properties/zones/${id}`);

export const fetchZoneForPointLocation = ({
  lat,
  lon,
}: {
  lat: number;
  lon: number;
}) =>
  client.get('/properties/zones/point-location', {
    params: {
      lat,
      lon,
    },
  });

export const fetchZoneProperties = (id: string) =>
  client.get(`/properties/zones/${id}/properties`);

export const createZone = (data: {
  name: string;
  region: string;
  isDemo?: boolean;
  zoneType: string;
  coordinates: number[][][];
}) => client.post('/properties/zones', data);

export const patchZone = ({
  id,
  name,
  region,
  isDemo,
  zoneType,
  coordinates,
}: {
  id: string;
  name?: string;
  region?: string;
  isDemo?: boolean;
  zoneType?: string;
  coordinates?: number[][][];
}) =>
  client.patch(`/properties/zones/${id}`, {
    name,
    region,
    isDemo,
    zoneType,
    coordinates,
  });

export const setZoneAdminProperty = ({
  id,
  propertyId,
}: {
  id: string;
  propertyId: string;
}) =>
  client.post(`/properties/zones/${id}/admin-property`, {
    propertyId,
  });

export const addAdjacentZone = ({
  id,
  adjacentZoneId,
}: {
  id: string;
  adjacentZoneId: string;
}) =>
  client.post(`/properties/zones/${id}/adjacent-zones`, {
    adjacentZoneId,
  });

export const removeAdjacentZone = ({
  id,
  adjacentZoneId,
}: {
  id: string;
  adjacentZoneId: string;
}) => client.delete(`/properties/zones/${id}/adjacent-zones/${adjacentZoneId}`);

export const fetchUnits = ({
  page,
  limit,
  postalCodes,
}: {
  page: number;
  limit: number;
  postalCodes: string[];
}) =>
  client.get('/properties/units', {
    params: {
      page,
      limit,
      postalCodes,
    },
  });

export const fetchUnit = (id: string) => client.get(`/properties/units/${id}`);

export const patchUnit = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) => client.patch(`/properties/units/${id}`, payload);

export const createUnit = (payload: unknown) =>
  client.post('/properties/units', payload);

export const deleteUnit = (id: string) =>
  client.delete(`/properties/units/${id}`);

export const fetchUnitProperties = (id: string) =>
  client.get(`/properties/units/${id}/properties`);

export const fetchPostalCodes = () => client.get('/properties/postal-codes');

export const fetchProperty = (id: string) =>
  client.get(`/properties/${id}/info`);

export const fetchPropertyBuildings = (id: string) =>
  client.get(`/properties/${id}/buildings`);

export const searchProperties = ({
  query,
  zoneIds,
  isAdminProperty,
}: {
  query: string;
  zoneIds?: string[];
  isAdminProperty?: boolean;
}) =>
  client.get('/properties/search', {
    params: {
      query,
      zoneIds,
      isAdminProperty,
    },
  });

export const fetchPropertyAdmins = ({
  zoneId,
  propertyId,
}: {
  zoneId: string;
  propertyId: string;
}) =>
  client.get('/properties/admins', {
    params: {
      zoneId,
      propertyId,
    },
  });

export const fetchProperties = ({
  page,
  limit,
  statuses,
  selectedZones,
  selectedPropertyPartners,
}: {
  page: number;
  limit: number;
  statuses?: string[];
  selectedZones?: { id: string }[];
  selectedPropertyPartners?: { id: string }[];
}) =>
  client.get('/properties', {
    params: {
      page,
      limit,
      statuses,
      zoneIds: selectedZones?.map((zone: { id: string }) => zone.id),
      propertyPartnerIds: selectedPropertyPartners?.map(
        (partner: { id: string }) => partner.id,
      ),
    },
  });

export const findPropertiesByRef = (ref: string) =>
  client.get(`/properties/refs/${ref}`);

export const exportProperties = () => client.get('/properties/csv');

export const exportPropertyCodes = () => client.get('/properties/codes/csv');

export const exportPropertyRegistrationRequests = () =>
  client.get('/properties/registration-requests/csv');

export const fetchPropertyRegistrationRequests = ({
  page,
  limit,
  statuses,
}: {
  page: number;
  limit: number;
  statuses?: string[];
}) =>
  client.get('/properties/registration-requests', {
    params: {
      page,
      limit,
      statuses,
    },
  });

export const fetchPropertyRegistrationRequest = (id: string) =>
  client.get(`/properties/registration-requests/${id}`);

export const patchPropertyRegistrationRequest = ({
  id,
  address,
  firstName,
  lastName,
  email,
  phoneNumber,
  status,
}: {
  id: string;
  address?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  status?: string;
}) =>
  client.patch(`/properties/registration-requests/${id}`, {
    address,
    firstName,
    lastName,
    email,
    phoneNumber,
    status,
  });

export const createProperty = async ({
  zoneId,
  address,
  name,
  propertyType,
  code,
}: {
  zoneId: string;
  address: string;
  name: string;
  propertyType: string;
  code: string;
}) =>
  client.post('/properties', {
    zoneId,
    address,
    name,
    propertyType,
    code,
  });

export const patchProperty = async ({
  id,
  name,
  shortName,
  propertyType,
  status,
  enforceApartmentNumberSelection,
  enforcePrivateIncident,
  disablePrivateGroups,
  disableDirectMessages,
  disableIncidents,
  disableNewsfeed,
  disableMarketPlace,
  excludeIncidentTypes,
}: {
  id: string;
  name?: string;
  shortName?: string;
  propertyType?: string;
  status?: string;
  enforceApartmentNumberSelection?: boolean;
  enforcePrivateIncident?: boolean;
  disablePrivateGroups?: boolean;
  disableDirectMessages?: boolean;
  disableIncidents?: boolean;
  disableNewsfeed?: boolean;
  disableMarketPlace?: boolean;
  excludeIncidentTypes?: string[];
}) =>
  client.patch(`/properties/${id}/info`, {
    name,
    shortName,
    propertyType,
    status,
    enforceApartmentNumberSelection,
    enforcePrivateIncident,
    disablePrivateGroups,
    disableDirectMessages,
    disableIncidents,
    disableNewsfeed,
    disableMarketPlace,
    excludeIncidentTypes,
  });

export const addPropertyUnit = async ({
  id,
  unitId,
}: {
  id: string;
  unitId: string;
}) => client.post(`/properties/${id}/units/${unitId}`);

export const addPropertyUnitWithBan = async ({
  id,
  address,
}: {
  id: string;
  address: string;
}) =>
  client.post(`/properties/${id}/units`, {
    address,
  });

export const removePropertyUnit = async ({
  id,
  unitId,
}: {
  id: string;
  unitId: string;
}) => client.delete(`/properties/${id}/units/${unitId}`);

export const createBuilding = async ({
  id,
  name,
  nbOfDwellings,
}: {
  id: string;
  name: string;
  nbOfDwellings?: number;
}) =>
  client.post(`/properties/${id}/buildings`, {
    name,
    nbOfDwellings,
  });

export const patchBuilding = async ({
  id,
  buildingId,
  name,
  nbOfDwellings,
  position,
}: {
  id: string;
  buildingId: string;
  name: string;
  nbOfDwellings?: number;
  position: string;
}) =>
  client.patch(`/properties/${id}/buildings/${buildingId}`, {
    name,
    nbOfDwellings,
    position,
  });

export const removePropertyBuilding = async ({
  id,
  buildingId,
}: {
  id: string;
  buildingId: string;
}) => client.delete(`/properties/${id}/buildings/${buildingId}`);

export const fetchPropertyCodes = ({
  page,
  limit,
  propertyId,
}: {
  page: number;
  limit: number;
  propertyId: string;
}) =>
  client.get(`/properties/${propertyId}/codes`, {
    params: {
      page,
      limit,
    },
  });

export const createPropertyCode = ({
  propertyId,
  code,
  validFrom,
  validUntil,
  userId,
}: {
  propertyId: string;
  code?: string;
  validFrom?: string;
  validUntil?: string;
  userId?: string;
}) =>
  client.post(`/properties/${propertyId}/codes`, {
    code,
    validFrom,
    validUntil,
    userId,
  });

export const addPropertyPartner = async ({
  id,
  partnerId,
}: {
  id: string;
  partnerId: string;
}) => client.post(`/properties/${id}/partners/${partnerId}`);

export const removePropertyPartner = async ({
  id,
  partnerId,
}: {
  id: string;
  partnerId: string;
}) => client.delete(`/properties/${id}/partners/${partnerId}`);
