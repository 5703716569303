import { Fragment, useState } from 'react';
import cx from 'classnames';
import { MenuItem } from 'react-bootstrap';
import { asyncContainer, Menu, Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { searchPosts } from '@@api/newsfeed';
import { searchProducts } from '@@api/market';
import { searchProperties } from '@@api/property';
import { searchRooms } from '@@api/im';
import { searchUsers } from '@@api/user';

const AsyncTypeahead = asyncContainer(Typeahead);

export default function SearchBox(props) {
  const [activeSearch, setActiveSearch] = useState(false);
  const [resultCategories, setResultCategories] = useState([
    { label: 'Properties', resultKey: 'properties', icon: 'lnr-apartment' },
    { label: 'Users', resultKey: 'users', icon: 'lnr-users' },
    { label: 'Market place', resultKey: 'products', icon: 'pe-7s-news-paper' },
    { label: 'News Feed', resultKey: 'posts', icon: 'pe-7s-news-paper' },
    { label: 'Rooms', resultKey: 'rooms', icon: 'lnr-bubble' },
  ]);

  const [globalSearchFilter, setGlobalSearchFilter] = useState({
    isLoading: false,
    query: '',
    options: [],
    selectedOptions: [],
  });

  const renderResultCategories = (results, menuProps) => {
    let index = 0;

    const items = resultCategories
      .filter(
        (resultCategory) =>
          results
            .filter((r) => r.resultKey === resultCategory.resultKey)
            .length,
      )
      .map((resultCategory) => (
        <div
          key={resultCategory.resultKey}
          className="global-search-results-wrapper"
        >
          {index !== 0 && <Menu.Divider />}

          <Menu.Header>
            <i className={'mr-1 icon ' + resultCategory.icon} />{' '}
            {resultCategory.label}
          </Menu.Header>

          {results
            .filter((r) => r.resultKey === resultCategory.resultKey)
            .map((i) => {
              let title = '';
              let subTitle = '';

              if (i.resultKey === 'products') {
                title = i.title;
                subTitle =
                  (i.productType === 'OBJECT' ? 'Biens > ' : 'Services > ') +
                  i.category.name;
              } else if (i.resultKey === 'properties') {
                title = i.name;
                subTitle = i.address;
              } else if (i.resultKey === 'rooms') {
                title = i.title;
                subTitle = i.propertyName;
              } else if (i.resultKey === 'users') {
                title = i.fullName;
                subTitle = i.email;
              } else if (i.resultKey === 'posts') {
                title = i.title;
                subTitle = i.description;
              }

              const item = (
                <MenuItem
                  key={index}
                  option={i}
                  href={'/' + resultCategory.resultKey + '/id/' + i.id}
                  position={index}
                >
                  <div className="ml-3 mb-1">
                    <small className="font-weight-bold">{title}</small>
                    <br />
                    <small className="text-black-50">{subTitle}</small>
                  </div>
                </MenuItem>
              );

              index += 1;
              return item;
            })}
        </div>
      ));

    return <Menu {...menuProps}>{items}</Menu>;
  };

  const search = async (query) => {
    setGlobalSearchFilter({
      ...globalSearchFilter,
      query,
      options: [],
      isLoading: true,
    });

    try {
      const [
        posts,
        products,
        properties,
        rooms,
        users,
      ] = await Promise.all([
        searchPosts(query),
        searchProducts(query),
        searchProperties({ query }),
        searchRooms(query),
        searchUsers(query),
      ]);

      setGlobalSearchFilter({
        ...globalSearchFilter,
        isLoading: false,
        options: [
          ...properties.data.map(p => ({
            ...p,
            resultKey: 'properties',
          })),
          ...users.data.map(p => ({
            ...p,
            resultKey: 'users',
          })),
          ...posts.data.map(p => ({
            ...p,
            resultKey: 'posts',
          })),
          ...products.data.map(p => ({
            ...p,
            resultKey: 'products',
          })),
          ...rooms.data.map(p => ({
            ...p,
            resultKey: 'rooms',
          })),
        ],
      });
    } catch (e) {
      toast.error('Failed to search');

      setGlobalSearchFilter({
        ...globalSearchFilter,
        isLoading: false,
        options: [],
      });
    }
  };

  return (
    <Fragment>
      <div className={cx('search-wrapper', { active: activeSearch })}>
        <div className="input-holder">
          <AsyncTypeahead
            id="globalSearch"
            isLoading={globalSearchFilter.isLoading}
            options={globalSearchFilter.options}
            clearButton
            delay={200}
            useCache={false}
            // onChange={options => {
            //   if (options && options.length > 0) {
            //     props.history.push('/' + options[0].resultKey + '/id/' + options[0].id);
            //   }
            // }}
            filterBy={(option) => true}
            positionFixed={true}
            searchText="Wait for it..."
            renderInput={(inputProps) => (
              <input
                type="text"
                className="search-input"
                placeholder="Type to search"
              />
            )}
            renderMenu={renderResultCategories}
            onSearch={search}
          />

          <button onClick={() => setActiveSearch(true)} className="search-icon">
            <span />
          </button>
        </div>
        <button onClick={() => setActiveSearch(false)} className="close" />
      </div>
    </Fragment>
  );
}
