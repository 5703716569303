import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setEnableMobileMenu } from '../../reducers/ThemeOptions';
import MetisMenu from 'react-metismenu';

export const MainNavItems = [
  // {
  //   icon: 'lnr-chart-bars',
  //   label: 'Dashboards',
  //   to: '/dashboards/main'
  // },
  {
    icon: 'lnr-rocket',
    label: 'Dashboards',
    content: [
      // {
      //   label: 'Test users data',
      //   to: '/dashboards/test-users-stats',
      // },
      {
        label: 'Market',
        to: '/dashboards/products',
      },
      {
        label: 'Newsfeed',
        to: '/dashboards/newsfeed',
      },
      {
        label: 'Messages',
        to: '/dashboards/messages',
      },
      {
        label: 'Properties',
        to: '/dashboards/properties',
      },
      {
        label: 'Users',
        to: '/dashboards/users',
      },
    ],
  },
  {
    icon: 'lnr-bubble',
    label: 'IM',
    content: [
      {
        label: 'Group chat messages',
        to: '/messages/groupchat/list',
      },
      {
        label: 'Private chat messages',
        to: '/messages/chat/list',
      },
      {
        label: 'Rooms',
        to: '/rooms/list',
      },
    ],
  },
  {
    icon: 'lnr-apartment',
    label: 'Properties & zones',
    content: [
      {
        label: 'Properties',
        to: '/properties/list',
      },
      {
        label: 'Property partners',
        to: '/property-partners/list',
      },
      {
        label: 'Property requests',
        to: '/property-requests/list',
      },
      {
        label: 'Units',
        to: '/property-units/list',
      },
      {
        label: 'Zones',
        to: '/zones/list',
      },
    ],
  },
  {
    icon: 'lnr-store',
    label: 'Market place',
    content: [
      {
        label: 'Categories',
        to: '/product-categories/list',
      },
      {
        label: 'Products & services',
        to: '/products/list',
      },
    ],
  },
  {
    icon: 'pe-7s-news-paper',
    label: 'Newsfeed',
    content: [
      {
        label: 'Posts',
        to: '/posts/list',
      },
    ],
  },
  // {
  //   icon: 'lnr-mustache',
  //   label: 'Partners',
  //   content: [
  //     {
  //       label: 'Partners administrators',
  //       to: '/partners/admins/list',
  //     },
  //     {
  //       label: 'Accounts',
  //       to: '/partners/users/list',
  //     },
  //   ]
  // },
  {
    icon: 'lnr-users',
    label: 'Residents',
    content: [
      {
        label: 'Deleted accounts',
        to: '/deleted-accounts/list',
      },
      {
        label: 'Sign up requests',
        to: '/signup-requests/list',
      },
      {
        label: 'Users',
        to: '/users/list',
      },
    ],
  },
];

const AdminNavItems = [
  {
    icon: 'lnr-cog',
    label: 'Admin',
    content: [
      {
        label: 'Admin users',
        to: '/admin/users/list',
      },
    ],
  },
];

class Nav extends Component {
  state = {};

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">Menu</h5>
        <MetisMenu
          content={MainNavItems}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />

        <h5 className="app-sidebar__heading"></h5>
        <MetisMenu
          content={AdminNavItems}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
