import React, { Fragment } from 'react';
//import {Link} from "react-router-dom";

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        {/*<div className="app-footer">*/}
        {/*<div className="app-footer__inner">*/}
        {/*<div className="app-footer-left">*/}
        {/*/!*<MegaMenuFooter/>*!/*/}
        {/*</div>*/}

        {/*<div className="app-footer-right">*/}
        {/*<div className="footer-dots">*/}
        {/*<Link to="/login" className="dot-btn-wrapper">*/}
        {/*<i className="dot-btn-icon lnr-bullhorn icon-gradient bg-mean-fruit"/>*/}
        {/*<div className="badge badge-dot badge-abs badge-dot-sm badge-danger">Notifications</div>*/}
        {/*</Link>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
      </Fragment>
    );
  }
}

export default AppFooter;
