import axios, { AxiosError } from 'axios';
import {
  loadAuthTokenFromLocalStorage,
  saveAuthTokenToLocalStorageV2 as saveAuthTokenToLocalStorage,
  removeAuthTokenFromLocalStorage,
  removeUserInfoFromLocalStorage,
  loadDeviceIdFromLocalStorage,
  saveDeviceIdToLocalStorage,
} from '@@utils/LocalStorageHelper';

export const cleanUpAfterLogout = () => {
  removeAuthTokenFromLocalStorage();
  removeUserInfoFromLocalStorage();
};

export const generateDeviceId = () =>
  `${Math.random().toString()};v=${process.env.REACT_APP_API_CLIENT_ID}`;

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Platform-Os': 'WEB/ADMIN',
    'X-Client-Id': process.env.REACT_APP_API_CLIENT_ID,
    'X-Language': process.env.REACT_APP_API_DEFAULT_LANGUAGE,
  },
});

const handleRefreshToken = async (url: string) => {
  if (/auth/.test(url) && !/admin/.test(url)) {
    return undefined;
  }

  const authToken = loadAuthTokenFromLocalStorage() || {};
  const { expiryDate, refreshToken } = authToken;
  let { accessToken } = authToken;

  if (!refreshToken) {
    cleanUpAfterLogout();
    return undefined;
  }

  if (!accessToken || !expiryDate || new Date(expiryDate) < new Date()) {
    const refreshTokenResp = (
      await client.post('/auth/refresh', {
        refreshToken,
      })
    ).data;

    accessToken = refreshTokenResp.access_token;
    saveAuthTokenToLocalStorage(refreshTokenResp);
    document.cookie = `token=${refreshTokenResp.refresh_token}; path=/ maxAge=${refreshTokenResp.expires_in}`;
  }

  return accessToken;
};

client.interceptors.request.use(async (req) => {
  if (!req.headers) {
    req.headers = {};
  }
  req.url = req.url || '';

  let deviceId = loadDeviceIdFromLocalStorage();
  if (!deviceId) {
    deviceId = generateDeviceId();
    saveDeviceIdToLocalStorage(deviceId);
  }
  req.headers['X-Device-Id'] = deviceId;

  const token = await handleRefreshToken(req.url);

  if (!token) {
    return req;
  }

  req.headers.Authorization = `Bearer ${token}`;

  return req;
});

client.interceptors.response.use(undefined, async (err: AxiosError) => {
  const originalRequest = err.config;

  if (!originalRequest?.url) {
    return Promise.reject(err);
  }

  if (err.response?.status === 401) {
    cleanUpAfterLogout();
    return Promise.reject(err);
  }

  return Promise.reject({
    ...err,
    ...err.response?.data,
  });
});

export default client;

