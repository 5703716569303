import client from '@@api/client';

export const requestMagicLink = (email: string) =>
  client.post<string>('/auth/admin/magic-link/request', { email });

export const login = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => client.post('/auth/login', {
  username,
  password,
  scope: 'admin',
});

export const logout = () =>
  client.post('/auth/logout');
