import { Fragment } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import { ListGroup, ListGroupItem } from 'reactstrap/lib';
import { MainNavItems } from '@@layout/AppNav/VerticalNavWrapper';
import AppHeader from '@@layout/AppHeader/';
import AppSidebar from '@@layout/AppSidebar/';
import AppFooter from '@@layout/AppFooter/';

export default function HomePage(props) {
  return (
    <Fragment>
      <AppHeader />

      <div className="app-main">
        <AppSidebar />

        <div className="app-main__outer">
          <div className="app-main__inner">
            <Row>
              {MainNavItems.map((item) => {
                return (
                  <Col md="4">
                    <Card className="mb-2">
                      <CardHeader>
                        <i className={`header-icon ${item.icon}`}> </i>
                        {item.label}
                      </CardHeader>
                      <CardBody>
                        <ListGroup flush>
                          {item.content.map((subItem) => {
                            return (
                              <ListGroupItem tag="a" href={subItem.to}>
                                {subItem.label}
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>

          <AppFooter />
        </div>
      </div>
    </Fragment>
  );
}
