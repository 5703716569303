import client from '@@api/client';
import { handleSortQuery, sortElem } from '@@utils/PaginationHelper';

export const fetchMe = () => client.get('/users/me');

export const fetchUsers = ({
  page,
  limit,
  types,
  statuses,
  selectedBuildings,
  selectedZones,
  selectedProperties,
  selectedPropertyPartners,
  sort,
}: {
  page: number;
  limit: number;
  types?: string[];
  statuses?: string[];
  selectedBuildings?: { id: string }[];
  selectedZones?: { id: string }[];
  selectedProperties?: { id: string }[];
  selectedPropertyPartners?: { id: string }[];
  sort?: sortElem[];
}) => client.get('/users', {
    params: {
      page,
      limit,
      types: types?.join(','),
      statuses: statuses?.join(','),
      buildingIds: selectedBuildings?.map((building: { id: string }) => building.id).join(','),
      zoneIds: selectedZones?.map((zone: { id: string }) => zone.id).join(','),
      propertyIds: selectedProperties?.map((property: { id: string; propertyId?: string; }) => property.id || property.propertyId).join(','),
      propertyPartnerIds: selectedPropertyPartners?.map((partner: { id: string }) => partner.id).join(','),
      sort: handleSortQuery(sort),
    },
  });


export const searchUsers = (query: string, propertyId?: string) =>
  client.get('/users/search', {
    params: {
      query,
      propertyId,
    },
  });

export const fetchUser = (id: string) =>
  client.get(`/users/${id}`);

export const updateUser = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) => client.post(`/users/${id}/info`, payload);

export const updateUserAccount = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) =>
  client.post(`/users/${id}/accounts/info`, payload);

export const updateUserEmail = ({
  id,
  email,
}: {
  id: string;
  email: string;
}) =>
  client.post(`/users/${id}/email`, { email });

export const updateUserPassword = ({
  id,
  password,
}: {
  id: string;
  password: string;
}) =>
  client.post(`/users/${id}/password`, {
    newPassword: password,
    password: 'admin',
  });

export const uploadPredefinedAvatar = ({
  id,
  avatarId,
} : {
  id: string,
  avatarId: number
}) =>
  client.post(`/users/${id}/avatar/default`, {
    avatarId,
  });

export const uploadImage = ({
  id,
  file,
} : {
  id: string;
  file: File;
}) => {
  const data = new FormData();
  data.append('file', file);

  return client.post(`/users/${id}/avatar`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteUserImage = (id: string) =>
  client.delete(`/users/${id}/avatar`);

export const removeUser = ({
  id,
  reason,
}: {
  id: string;
  reason: string;
}) =>
  client.delete(`/users/${id}`, {
    data: {
      reason,
    },
  });

export const fetchSignupRequests = ({
  page,
  limit,
  selectedSignUpOriginTypes,
  selectedAddressValidationMethods,
  statuses,
  selectedZones,
  selectedProperties,
  selectedPropertyPartners,
  sort,
}: {
  page: number;
  limit: number;
  selectedSignUpOriginTypes?: string[];
  selectedAddressValidationMethods?: string[];
  statuses?: { id: string }[];
  selectedZones?: { id: string }[];
  selectedProperties?: { id: string }[];
  selectedPropertyPartners?: { id: string }[];
  sort?: sortElem[];
}) =>
  client.get('/users/signup-requests', {
    params: {
      page,
      limit,
      signUpOriginTypes: selectedSignUpOriginTypes?.join(','),
      addressValidationMethods: selectedAddressValidationMethods?.join(','),
      statuses: statuses?.map((zone: { id: string }) => zone.id).join(','),
      zoneIds: selectedZones?.map((zone: { id: string }) => zone.id).join(','),
      propertyIds: selectedProperties?.map((property: { id: string }) => property.id).join(','),
      propertyPartnerIds: selectedPropertyPartners?.map((partner: { id: string }) => partner.id).join(','),
      sort: handleSortQuery(sort),
    },
  });

export const exportSignupRequests = () =>
  client.get('/users/signup-requests/csv');

export const fetchSignupRequest = (id: string) =>
  client.get(`/users/signup-requests/${id}`);

export const deleteSignupRequest = (id: string) =>
  client.delete(`/users/signup-requests/${id}`);

export const patchSignupRequest = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) =>
  client.patch(`/users/signup-requests/${id}`, payload);

export const validateUserAddress = (id: string) =>
  client.post(`/users/signup-requests/${id}/manual-address-validation`);

export const resendValidationEmail = (id: string) =>
  client.post(`/users/signup-requests/${id}/send-validation-email`);

export const validateUserEmail = (id: string) =>
  client.post(`/users/signup-requests/${id}/validation`);

export const fetchUserRooms = ({
  id,
  page,
  limit,
}: {
  id: string;
  page: number;
  limit: number;
}) =>
  client.get(`/users/${id}/rooms`, {
    params: {
      page,
      limit,
    },
  });

export const fetchUserChats = ({
    id,
    page,
    limit,
  }: {
    id: string;
    page: number;
    limit: number;
  }) =>
  client.get(`/users/${id}/privateChats`, {
    params: {
      page,
      limit,
    },
  });

export const fetchUserProducts = ({
    id,
    page,
    limit,
  }: {
    id: string;
    page: number;
    limit: number;
  }) =>
  client.get(`/users/${id}/products`, {
    params: {
      page,
      limit,
    },
  });

export const removePushSubscription = ({
  id,
  playerId,
  platform,
}: {
  id: string;
  playerId: string;
  platform: string;
}) => client.delete(`/users/${id}/push-notifications`, {
  data: {
    playerId,
    platform,
  },
});

export const fetchAdmins = ({
  page,
  limit,
}: {
  page: number;
  limit: number;
}) =>
client.get('/users/admins', {
  params: {
    page,
    limit,
  },
});

export const fetchAdmin = (id: string) =>
  client.get(`/users/admins/${id}`);

export const patchAdmin = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) =>
  client.patch(`/users/admins/${id}`, payload);

export const createAdminUser = ({
  username,
  name,
  password,
} : {
  username: string;
  name: string;
  password: string;
}) =>
  client.post('/users/admins', {
    username,
    name,
    password,
  });

export const createZoneAdminUser = ({
  email,
  firstName,
  zoneId,
} : {
  email: string;
  firstName: string;
  zoneId: string;
}) =>
  client.post(`/users/zones/${zoneId}/admins`, {
    email,
    firstName,
  });

export const createPropertyAdminUser = (propertyId: string) =>
  client.post(`/users/properties/${propertyId}/admins`);

export const fetchPartnerAdmins = ({
  page,
  limit,
  partnerIds,
}: {
  page: number;
  limit: number;
  partnerIds: string[];
}) =>
client.get('/users/partners', {
  params: {
    page,
    limit,
    partnerIds,
  },
});

export const fetchPartnerAdmin = (id: string) =>
  client.get(`/users/partners/${id}`);

export const createPartnerAdmin = ({
  partnerId,
  username,
  name,
  password,
}: {
  partnerId: string;
  username: string;
  name: string;
  password: string;
}) =>
client.post('/users/partners', {
  partnerId,
  username,
  name,
  password,
});

export const patchPartnerAdmin = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) =>
  client.patch(`/users/partners/${id}`, payload);
