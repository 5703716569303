import client from '@@api/client';
import { handleSortQuery, sortElem } from '@@utils/PaginationHelper';

export const fetchPosts = ({
  page,
  limit,
  statuses,
  userStatuses,
  postTypes,
  selectedZones,
  sort,
}: {
  page: number;
  limit: number;
  statuses?: string[];
  userStatuses?: string[];
  postTypes?: string[];
  selectedZones?: { id: string }[];
  sort?: sortElem[];
}) =>
  client.get('/newsfeed/admin/posts', {
    params: {
      page,
      limit,
      statuses: statuses?.join(','),
      userStatuses: userStatuses?.join(','),
      postTypes: postTypes?.join(','),
      zoneIds: selectedZones?.map((zone: { id: string }) => zone.id).join(','),
      sort: handleSortQuery(sort),
    },
  });

export const searchPosts = (query: string) =>
  client.get('/newsfeed/posts/search', {
    params: {
      query,
    },
  });

export const fetchPost = (id: string) =>
  client.get(`/newsfeed/posts/${id}`);

export const patchPost = ({
  id,
  ...payload
}: {
  id: string;
  payload: unknown;
}) => client.patch(`/newsfeed/posts/${id}`, payload);

export const addPostImage = (id: string, file: File) => {
  const formData = new FormData();
  // @ts-ignore
  formData.append('file', file);

  return client.post(
    `/newsfeed/posts/${id}/images`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const removePostImage = ({
  id,
  thumbnail,
}: {
  id: string;
  thumbnail: string;
}) =>
  client.delete(`/newsfeed/posts/${id}/images`, {
  data: {
    thumbnail,
  },
});

export const fetchPostComments = ({
  id,
  page,
  limit,
}: {
  id: string;
  page: number;
  limit: number;
}) => client.get(`/newsfeed/posts/${id}/comments`, {
  params: {
    page,
    limit,
  },
});

type AddPostInput = {
  title: string;
  body: string;
  postType: string;
  notifyUsers: boolean;
  propertyId: string;
  userId: string;
  lostFoundType?: string;
  images?: File[];
  startDate?: string;
  endDate?: string;
  location?: string;
}

export const createPost = (data: AddPostInput) => {
  const formData = new FormData();
  if (data.images) {
    for (const image of data.images) {
      formData.append('file', image);
    }
  }

  formData.append('title', data.title);
  formData.append('body', data.body);
  formData.append('notifyUsers', String(data.notifyUsers));
  formData.append('propertyId', data.propertyId);
  formData.append('userId', data.userId);

  if (data.lostFoundType) {
    formData.append('lostFoundType', data.lostFoundType);
  }
  if (data.startDate) {
    formData.append('startDate', data.startDate);
  }
  if (data.endDate) {
    formData.append('endDate', data.endDate);
  }
  if (data.location) {
    formData.append('location', data.location);
  }

  return client.post(
    `/newsfeed/posts/${data.postType}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

