const LOCALSTORAGE_AUTH_TOKEN_KEY = '___token';
const LOCALSTORAGE_USER_INFO_KEY = '___me';
const LOCALSTORAGE_DEVICE_ID_KEY = '___=deviceId';

export const saveToLocalStorage = (
  key: string,
  value: Record<string, unknown> | string,
) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const loadFromLocalStorage = (key: string) => {
  const serializedItem = localStorage.getItem(key);

  if (serializedItem) {
    try {
      return JSON.parse(serializedItem);
    } catch (e) {
      localStorage.removeItem(key);
    }
  }

  return null;
};

export const removeFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);

export const saveAuthTokenToLocalStorageV2 = ({
  access_token,
  refresh_token,
  expires_in,
}: {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}) =>
  saveToLocalStorage(LOCALSTORAGE_AUTH_TOKEN_KEY, {
    accessToken: access_token,
    refreshToken: refresh_token,
    expiryDate: new Date(Date.now() + expires_in * 1000),
  });

export const loadAuthTokenFromLocalStorage = () => {
  return loadFromLocalStorage(LOCALSTORAGE_AUTH_TOKEN_KEY);
};

export const removeAuthTokenFromLocalStorage = () => {
  removeFromLocalStorage(LOCALSTORAGE_AUTH_TOKEN_KEY);
};

/**
 * USER INFO
 */
export const saveUserInfoToLocalStorage = (value: Record<string, unknown>) =>
  saveToLocalStorage(LOCALSTORAGE_USER_INFO_KEY, value);

export const loadUserInfoFromLocalStorage = () =>
  loadFromLocalStorage(LOCALSTORAGE_USER_INFO_KEY);

export const removeUserInfoFromLocalStorage = () =>
  removeFromLocalStorage(LOCALSTORAGE_USER_INFO_KEY);

/**
 * DEVICE ID
 */
 export const saveDeviceIdToLocalStorage = (value: Record<string, unknown>) =>
 saveToLocalStorage(LOCALSTORAGE_DEVICE_ID_KEY, value);

export const loadDeviceIdFromLocalStorage = () =>
 loadFromLocalStorage(LOCALSTORAGE_DEVICE_ID_KEY);

export const removeDeviceIdFromLocalStorage = () =>
 removeFromLocalStorage(LOCALSTORAGE_DEVICE_ID_KEY);

