import './polyfills';
import 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Popper from 'popper.js';
import './assets/base.scss';
import Main from './pages/Main';
import configureStore from './config/configureStore';

const store = configureStore();
const rootElement = document.getElementById('root');

// see: https://github.com/twbs/bootstrap/issues/23590
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = !(
  window.devicePixelRatio < 1.5 && /Win/.test(navigator.platform)
);

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Component />
      </Router>
    </Provider>,
    rootElement,
  );
};

renderApp(Main);
