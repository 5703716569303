import React, { Fragment } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import HeaderLogo from '../AppLogo';
import SearchBox from './Components/SearchBox';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { withRouter } from 'react-router-dom';
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import avatar1 from '@@assets/utils/images/avatars/user-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  loadUserInfoFromLocalStorage,
  removeAuthTokenFromLocalStorage,
  removeUserInfoFromLocalStorage,
  saveUserInfoToLocalStorage,
} from '@@utils/LocalStorageHelper';
import { fetchMe } from '@@api/user';
import { logout as logUserOut } from '@@api/auth';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      userName: '',
      email: '',
      ip: '',
      deviceId: '',
      logoutInProgress: false,
    };

    this.refreshUserInfo = this.refreshUserInfo.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const data = loadUserInfoFromLocalStorage();

    if (data) {
      this.setState({
        id: data.id,
        name: data.name,
        email: data.email,
        ip: data.ip,
        deviceId: data.deviceId,
      });
    }
  }

  async refreshUserInfo() {
    try {
      const { data } = await fetchMe();
      this.setState(data);
      saveUserInfoToLocalStorage(data);
    } catch (e) {
      toast.error('failed to load user data');
    }
  }

  async logout() {
    this.setState({
      logoutInProgress: true,
    });

    try {
      await logUserOut();
      removeAuthTokenFromLocalStorage();
      removeUserInfoFromLocalStorage();

    } catch (e) {
      toast.error('Failed to logout properly');
    }

    this.props.history.push('/login');

    this.setState({
      logoutInProgress: false,
    });
  }

  render() {
    let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } =
      this.props;

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          className={cx('app-header', headerBackgroundColor, {
            'header-shadow': enableHeaderShadow,
          })}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo />

          <div
            className={cx('app-header__content', {
              'header-mobile-open': enableMobileMenuSmall,
            })}
          >
            <div className="app-header-right">
              <SearchBox />

              {/* USER BOX */}
              <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                      <UncontrolledButtonDropdown>
                        <DropdownToggle color="link" className="p-0">
                          <img width={42} src={avatar1} alt="" />
                          <FontAwesomeIcon
                            className="ml-2 opacity-8 text-white"
                            icon={faAngleDown}
                          />
                        </DropdownToggle>

                        <DropdownMenu
                          right
                          className="rm-pointers dropdown-menu-lg"
                        >
                          <div className="dropdown-menu-header">
                            <div className="dropdown-menu-header-inner text-center">
                              <div className="text-primary text-center">
                                <strong>{this.state.name}</strong>
                                <br />
                                {this.state.email} [{this.state.id}]
                                <br />
                                Ip: {this.state.ip}
                                <br />
                                Device Id: {this.state.deviceId}
                              </div>

                              <hr />

                              <Button
                                onClick={this.refreshUserInfo}
                                className="btn btn-pill btn-secondary btn-shadow btn-shine mr-2"
                              >
                                Refresh user info
                              </Button>

                              <Button
                                disabled={this.state.logoutInProgress}
                                className="btn btn-pill btn-primary btn-shadow btn-shine"
                                onClick={this.logout}
                              >
                                Logout
                              </Button>
                            </div>
                          </div>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>

                    <div className="widget-content-left  ml-3 header-user-info">
                      <div className="widget-heading">
                        {' '}
                        <span className="text-white">
                          {this.state.name}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
